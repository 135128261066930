export const scriptCookies = {
	'es-CO': [
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			dataDomainScriptValue: 'a1157aa4-24bd-406d-9e19-a6f23dc0d5ef',
		},
	],
	'es-DO': [
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			dataDomainScriptValue: '46735165-1fa4-452e-a934-ed702b86e264',
		},
	],
	'es-EC': [
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			dataDomainScriptValue: '8bb4d66e-9d59-49b1-86e4-60357d465a6b',
		},
	],
	'es-PR': [
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			dataDomainScriptValue: '571c7f6b-1a82-4eb9-b266-472cb2876587',
		},
	],
	es: [],
}
