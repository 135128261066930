import React from "react";
import Fab from '@mui/material/Fab'
import './section-home-products.scss'

export default function SectionHomeProducts({ infoContent }) {
  const {
    productsModuleImage,
    productsModuleDescription,
    productsModuleCategory,
  } = infoContent;

  return (
    <section id="home-products" className="f-content-products-home">
      <div className="f-content-products-image">
        <div
          className="f-content-products-image-img"
          style={{
            background: `url("${productsModuleImage.file.url}") no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        ></div>
      </div>
      <div className="f-content-products-text">
        <div className="f-content-products-description">
          {productsModuleDescription.productsModuleDescription}
        </div>
        <div className="f-content-products-categories">
          { productsModuleCategory.map( (category) => {
              return (
                <a href={`/productos/${category.slug}`} className={"nostyle"}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    className="f-content-products-redirection"
                  >
                    {category.title.title}
                  </Fab>
                </a>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}