import React, { useState } from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/organisms/layout/layout';
import HomeContent from '../components/organisms/home-content/home-content';


import useMobileMode from '../hooks/useMobileMode';
import favicon from '../images/favicon.ico';
import '../styles/index.scss';

const IndexPage = () => {
  const { isMobile } = useMobileMode();
  const [modalActive, setmodalActive] = useState(null)
  const menuActive = 0;
  const classes = {
    main: 'f-main',
    footer: 'f-footer',
  };

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Layout
        menuActive={menuActive}
        classes={classes}
        setmodalActive={setmodalActive}
        modalActive={modalActive}
        title={
          process.env.LOCALE === 'es-CO'
            ? 'Soluciones en higiene para el hogar - Familia Hogar'
            : process.env.LOCALE === 'es-EC'
            ? 'Soluciones de higiene sostenible - Familia Hogar'
            : process.env.LOCALE === 'es-DO'
            ? 'Somos expertos en soluciones de higiene - Familia Hogar'
            : process.env.LOCALE === 'es-PR'
            ? 'Expertos en soluciones sostenibles de higiene - Familia hogar'
            : ''
        }
        slug={'/'}
      >
        <HomeContent isMobile={isMobile} setmodalActive={setmodalActive} />
      </Layout>
    </>
  );
};

export default IndexPage;
