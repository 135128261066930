import React from 'react';
import './section-home-sustainibility.scss';
import Fab from '@mui/material/Fab'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'

export default function SectionHomeSustainibility({ infoContent, isMobile }) {
  const {
    environmentalImage,
    environmentalTitle,
    environmentalDescription,
    sostenibilityLink
  } = infoContent;

  const datalayerConocecomo = () => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Home',
      category: 'Sostenibilidad',
      action: 'click',
      label: 'Conoce cómo',
      location: location,
      user_data: [],
      log_status: '',
    })
  }

  return (
    <section id="home-sustainibility" className="f-content-important-home">
      {isMobile ? (
        <h1 className="f-content-important-title">{environmentalTitle}</h1>
      ) : null}
      <div className="f-content-important-image">
        <div
          className="f-content-important-image-img"
          style={{
            background: `url("${environmentalImage.file.url}") no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        ></div>
      </div>
      <div className="f-content-important-text">
        {!isMobile ? (
          <h1 className="f-content-important-title">
            {environmentalTitle}
          </h1>
        ) : null}

        <div className="f-content-important-description">
          {environmentalDescription.environmentalDescription}
        </div>
        <a href={sostenibilityLink} className={"nostyle"} onClick={() => datalayerConocecomo()}>
          <Fab
            variant="extended"
            size="medium"
            aria-label="add"
            className="f-content-important-redirection"
          >
            conoce cómo
        </Fab>
        </a>
      </div>
    </section>
  );
}
