import React, { useEffect } from 'react'
import './card-article.scss'
import { useState } from 'react'
import MagazinService from '../../../services/magazin-service'
import { siteUrl } from '../../../shared/utils/siteUrl'
import { getColorByCategory } from '../../../shared/utils/getColorCategory'
import { getMonth } from '../../../shared/utils/getMonth'
import useMobileMode from '../../../hooks/useMobileMode'
import { LocalStorage } from '../../../shared/utils/storage'
import {removeDoubleSlash} from '../../../shared/utils/urlUtil'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

/**
 * Props -> article = Object with title, category, subtitle, contentfulId, type, img and description
 */

const CardArticle = (props) => {
    const { isMobile } = useMobileMode();
    const { article, likesList } = props

    const [hoverLike, sethoverLike] = useState(false)
    const [isLocalLiked, setisLocalLiked] = useState(false)
    const [likesCount, setlikesCount] = useState(null)

    const iconLiked = <i className="fas fa-heart"></i>
    const iconUnliked = <i className="far fa-heart"></i>

    const date = {
        day: article.fechaDePublicacionDelArticulo.split('/')[2],
        month: getMonth(article.fechaDePublicacionDelArticulo.split('/')[1] - 1),
        year: article.fechaDePublicacionDelArticulo.split('/')[0]
    }
    const dateToString = date.day + ' de ' + date.month.toLowerCase() + ' de ' + date.year

    const getIconByType = (type) => {
        if (type === 'Paso a Paso') {
            return <i className="fal fa-images"></i>
        } else if (type === 'Artículo') {
            return <i className="fal fa-file-invoice"></i>
        } else if (type === 'Tips') {
            return <i className="fal fa-lightbulb"></i>
        } else if (type === 'Video') {
            return <i className="fal fa-play-circle"></i>
        } else {
            return <i className="fal fa-lightbulb"></i>
        }
    }

    useEffect(() => {
        if(LocalStorage.getItem('ARTICLES_LIKES')){
            let localList = LocalStorage.getItem('ARTICLES_LIKES')
            if (localList.includes(article.contentful_id)) {
                setisLocalLiked(true)
            }
        }
        
    }, [])

    const handleClickLike = (e, id) => {
        e.preventDefault();

        if (isLocalLiked) { //set unlike

            let payload = {
                like: false,
                cmsId: id
            }

            MagazinService.setLikeStatus(payload).then(res => {
                if (200 === res.status) {
                    setlikesCount(res.data)
                    let localList = LocalStorage.getItem('ARTICLES_LIKES');
                    LocalStorage.setItem('ARTICLES_LIKES', localList.filter(e => e !== id))
                    setisLocalLiked(false)
                    sethoverLike(false)
                } else {
                    console.log(res)
                }
            }).catch(e => console.log(e))
        } else { //set like

            let payload = {
                like: true,
                cmsId: id
            }

            MagazinService.setLikeStatus(payload).then(res => {
                if (200 === res.status) {
                    setlikesCount(res.data)
                    if (LocalStorage.getItem('ARTICLES_LIKES')) {
                        let localList = LocalStorage.getItem('ARTICLES_LIKES')
                        LocalStorage.setItem('ARTICLES_LIKES',localList.concat([id]))
                        setisLocalLiked(true)

                    } else {
                       LocalStorage.setItem('ARTICLES_LIKES', [id])
                        setisLocalLiked(true)
                    }
                } else {
                    console.log(res)
                }
            }).catch(e => console.log(e))
        }
    }

    const formatNumber = (num) => {
        if (num > 999999) {
            return parseInt(num / 1000000, 10).toString() + 'M'
        } else if (num > 999) {
            return parseInt(num / 1000, 10).toString() + 'K'
        } else {
            return num
        }
    }

    const datalayerLikesArticles = (likesNumber, subCategory, articleTitle, articleType) => {      
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
          event: 'Interacciones_Artículos',
          category: 'FamiTips',
          action: 'click',
          label: 'likes',
          likes: `${likesNumber}`,
          sub_category: subCategory,
          article_name: articleTitle,
          article_type: articleType,
          location: location,
          user_data: [],
          log_status: ''
        })
    }

    return (
        <a className="f-card-article" href={siteUrl + removeDoubleSlash('/famitips/' + article.slug)} onClick={props.onClickAction}>
            {article.bannerDelArticulo && <img
                className="f-card-article-bkg"
                src={article.bannerDelArticulo[isMobile ? 1 : 0].file.url}
                alt={article.bannerDelArticulo[isMobile ? 1 : 0].description}
            />}
            <div className="f-top-card-article">
                <img
                    className="f-top-card-article-img"
                    alt={article.previsualizacionDelArtculo.description}
                    src={article.previsualizacionDelArtculo.file.url}
                />
                <h3 className="f-top-card-text">
                    {article.tituloDelArticulo}
                </h3>
                <span className="f-icon-container">
                    {getIconByType(article.tipoDelArticulo)}
                </span>
            </div>
            <div className="f-bottom-card-article">
                <div className="f-category-likes-container">
                    <h4 className={"f-category-article f-background-category-article-" + getColorByCategory(article.categoriaDelArticulo[0].description.description)}>
                        {article.categoriaDelArticulo[0].description.description.toUpperCase()}
                    </h4>
                    <span className="f-articles-likes" 
                        onClick={e => {
                            handleClickLike(e, article.contentful_id)
                            const likesNumber = likesCount ?
                                formatNumber(likesCount)
                                :
                                likesList.filter(e => e.cmsId === article.contentful_id).length === 0 ?
                                    0
                                    :
                                    formatNumber(likesList.filter(e => e.cmsId === article.contentful_id)[0].like)
                            const subCategory = article.categoriaDelArticulo[0]?.description.description || 'Todos'
                            datalayerLikesArticles(likesNumber,subCategory,article.tituloDelArticulo||'', article.tipoDelArticulo||'')
                        }} 
                        onMouseEnter={() => sethoverLike(true)} 
                        onMouseLeave={() => sethoverLike(false)}>
                        {likesCount ?
                            formatNumber(likesCount)
                            :
                            likesList.filter(e => e.cmsId === article.contentful_id).length === 0 ?
                                0
                                :
                                formatNumber(likesList.filter(e => e.cmsId === article.contentful_id)[0].like)
                        } {isLocalLiked || hoverLike ? iconLiked : iconUnliked}
                    </span>
                </div>
                
                <time datetime={dateToString} className="f-date-container">
                    {dateToString}
                </time>
                
                <h3 className="f-title-container">
                    {article.tituloDelArticulo}
                </h3>
                <p className="f-description-container">
                    {article.descripcinCortaDelArticulo.descripcinCortaDelArticulo}
                </p>
            </div>
        </a>
    )
}

export default CardArticle
