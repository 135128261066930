/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { getWindow } from '../utils/utilities-dom';
import { countriesInfo } from '../utils/countriesInfo'
import {removeDoubleSlash} from '../utils/urlUtil'
import { scriptCookies } from '../utils/scriptCookies';

function SEO(props) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            locale
          }
        }
      }
    `
  );

  const lang = props.lang || site.siteMetadata.locale;
  const meta = props.meta;
  const metaTitle = props.title || site.siteMetadata.title;
  const metaDescription = props.description || site.siteMetadata.description;
  const author = site.siteMetadata.author;
  const siteUrl = props.url || site.siteMetadata.siteUrl;
  const ogImageUrl = props.image || siteUrl + '/logo.png';
  const script = props.script;
  const slug = props.slug || getWindow()?.location?.pathname || '/';
  let alternatesInvalidCountries = props.alternatesInvalidCountries || [];

  // Organization Schema
  const org = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'FAMILIA',
    'alternateName': author,
    'url': siteUrl,
    'logo': siteUrl + '/logo.png',
    'contactPoint': {
      '@type': 'ContactPoint',
      'telephone': '+57018000524848',
      'contactType': 'customer service',
      'contactOption': 'TollFree',
      'areaServed': 'CO',
      'availableLanguage': 'es',
    },
    'sameAs': 'https://www.facebook.com/CosasdeFamilia/',
  };

  const schemaOrgJSONLD = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(org),
  };

  // WebSite Schema
  const website = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": siteUrl + "#website",
    "headline": "Familia",
    "name": "Familia",
    "description": site.siteMetadata.description,
    "url": siteUrl,
    "potentialAction": {
      "@type": "SearchAction",
      "target": siteUrl + "?s={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  const schemaWebSiteJSONLD = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(website),
  };

  let schemas;
  if (script) {
    schemas = [schemaOrgJSONLD, schemaWebSiteJSONLD, script];
  } else {
    schemas = [schemaOrgJSONLD, schemaWebSiteJSONLD];
  }

  const urlFinal = new URL(siteUrl + slug);

  let hrefLangList = [];

  countriesInfo.forEach(ele => {
    let slugWithOutInitialSlash = slug.replace(/^\//, "")
    const finalUrl = new URL(removeDoubleSlash(ele.dominio+slugWithOutInitialSlash))
    const isInvalidCountry = alternatesInvalidCountries.find(
      country => ele.codigoIcu.toLowerCase()?.includes(country?.toLowerCase())
    )

    if (!isInvalidCountry) {
      hrefLangList.push({
        rel: "alternate",
        href: finalUrl.href,
        hrefLang: ele.codigoIcu,
      })
    }
  })

  const SCRIPT_COOCKIES = scriptCookies[process.env.LOCALE]  || scriptCookies["es"]

  useEffect(() => {
    setTimeout(() => {
      // Crear el scriptCookies
      const scriptCookies1 = document.createElement('script');
      scriptCookies1.type = SCRIPT_COOCKIES[0].type;
      scriptCookies1.src = SCRIPT_COOCKIES[0].src;
      scriptCookies1.setAttribute('data-document-language', 'true');
      scriptCookies1.charset = 'UTF-8';
      scriptCookies1.setAttribute('data-domain-script', SCRIPT_COOCKIES[0].dataDomainScriptValue);
      scriptCookies1.async = true;
      // Agregar los scripts al final del cuerpo del documento
      document.body.appendChild(scriptCookies1);
      
      // Limpiar los scripts cuando el componente se desmonta
      return () => {
        document.body.removeChild(scriptCookies1);
      };
    }, 3000);
  }, []);

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={`%s`}
      link={[
        {
          "rel": "icon",
          "type": "image/png",
          "href": "/favicon.ico"
        },
        {
          rel: 'alternate',
          href: `https://www.familia.com.co${slug}`,
          hrefLang: 'es',
        },
        {
          rel: 'alternate',
          href: `https://www.familia.com.co${slug}`,
          hreflang: 'x-default',
        },
      ].concat(hrefLangList)}
      meta={[
        {
          property: `fb:app_id`,
          content: `159486757429574`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: ogImageUrl,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: urlFinal,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImageUrl,
        },
        {
          name: 'google-site-verification',
          content: process.env.GOOGLE_SITE_VERIFICATION
        },
      ].concat(meta)}
      script={[schemas]}
    />
  );
}

SEO.defaultProps = {
  lang: `es-CO`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;