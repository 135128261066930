import React, { useEffect, useState } from 'react'
import './section-card-articles.scss'
import useMobileMode from '../../../hooks/useMobileMode';
import CardArticle from '../card-article/card-article';
import MagazinService from '../../../services/magazin-service';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode';

/**
 * Props -> articlesList = Array with X items-object with title, category, subtitle, contentfulId, type, img and description
 */

const SectionCardArticles = (props) => {
    const { articlesList, isFamiTips = false } = props
    const { isMobile } = useMobileMode();
    const [likesList, setlikesList] = useState([])
    const { homeLink } = useHomeLinkMode()

    useEffect(() => {
        MagazinService.getLikesList().then(res => {
            if (200 === res.status) {
                setlikesList(res.data)
            } else {
                console.log(res)
            }
        }).catch(e => console.log(e))
    }, [])

    const datalayerArticlesFamiTips = (labelText, slug) => {      
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
            event: 'Interacciones_Articulos',
            category: 'FamiTips',
            action: 'click',
            label: labelText,
            sub_category: 'Todos',
            article_name: labelText,
            article_type: 'Articulos',
            location: location,
            user_data: [],
            log_status: ''
          })
          pushDataLayerEvent({
            event: 'article_click',
            link_text: labelText,
            link_url: homeLink+'famitips/'+slug,
            article_name: labelText,
        })
    
      }

    return (
        <section className="f-section-card-articles">
            {articlesList.map((article, index) => {
                return (
                    <CardArticle article={article} likesList={likesList} key={index} isFamiTips={isFamiTips} onClickAction={(e) => {
                      datalayerArticlesFamiTips(article.tituloDelArticulo, article.slug);
                    }} />
                )
            })}
            {isMobile && <span className="f-fake-box" />}
        </section>
    )
}

export default SectionCardArticles
