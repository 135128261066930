export const countriesInfo = [
    {
      name: "COLOMBIA",
      codigoIcu: "es-CO",
      prefix: "+57",
      code: 1,
      cellDig: 10,
      dominio: "https://www.familia.com.co/",
    },
    {
      name: "REPÚBLICA DOMINICANA",
      codigoIcu: "es-DO",
      prefix: "+1",
      code: 13,
      cellDig: 10,
      dominio:"https://www.familia.com.do/"
    },
    {
      name: "ECUADOR",
      codigoIcu: "es-EC",
      prefix: "+593",
      code: 5,
      cellDig: 10,
      dominio:"https://www.familia.com.ec/"
    },
    {
      name: "PUERTO RICO",
      codigoIcu: "es-PR",
      prefix: "+1",
      code: 12,
      cellDig: 10,
      dominio:"https://www.familia.com.pr/"
    },
  ]