import axios from 'axios';
import * as endpoints from '../shared/constants/api-constants';


class MagazinService {
    static getLikesList() {
        return axios.get(endpoints.likes + '?countryId=' + process.env.COUNTRY_CODE).then(resp => resp.data)
    }

    static setLikeStatus(payload) {
        payload.countryId = process.env.COUNTRY_CODE
        return axios.post(endpoints.likes, payload).then(resp => resp.data)
    }

    static getArticleLike(id) {
        return axios.get(endpoints.likes + '?countryId=' + process.env.COUNTRY_CODE + '&cmsId=' + id).then(resp => resp.data)
    }
}

export default MagazinService;