export const getColorByCategory = (category) => {

    if (category === 'Sostenibilidad') {
        return 'green'
    } else if (category === 'Vida sana') {
        return 'blue'
    } else if (category === 'Hogar') {
        return 'yellow'
    } else {
        return 'pink'
    }
}